import React from "react";
import { SEO, MarkdownContent } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class PrivacyPolicyPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.illusionssalonspa.com/privacy-policy/"}
        />
        <Grid
          className={"custom-page-privacy-policy custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"mxqogipvsbkufnvb"}
            style={{ padding: "100px" }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"qzdvqhlmoufkhhlw"}
              style={{ padding: 16 }}
              width={16}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "0c858632-7016-4bae-b589-6434e9e93d3a",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"wnbtcgdeoisxplkm"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
          >
            <Grid.Column
              className={"agwovafsntbisnjz"}
              style={{ padding: 16 }}
              width={12}
              textAlign={"right"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "c2b03b59-1309-4017-8a5a-d1850335d4e9",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"vpsyoxkzlqqmmzcx"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"iiksmpabaozmhjsc"}
              style={{ padding: 16 }}
              width={10}
              textAlign={"left"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "0099ee7e-afdd-4f93-910b-eaac0dab49d4",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 29284 }) {
      title
      seoTitle
      description
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
